export default function isDarkColor(hex) {
  let r = 0;
  let g = 0;
  let b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`;
    g = `0x${hex[2]}${hex[2]}`;
    b = `0x${hex[3]}${hex[3]}`;
  // 6 digits
  } else if (hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`;
    g = `0x${hex[3]}${hex[4]}`;
    b = `0x${hex[5]}${hex[6]}`;
  }
  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return (a > 0.5);
}
