<template>
  <div
    :class="['card', {shadow}]"
    :style="{backgroundColor, color, fontFamily, borderRadius}"
  >
    <img
      v-show="buttonState"
      class="p-1 is-clickable"
      style="position:absolute;right: 0"
      :src="isPlayer ? iconPause : iconPlay"
      :title="isPlayer ? 'Pause' : 'Play'"
      alt="Sound"
      @click="soundToggle"
    >
    <a
      href="https://co-w.io/"
      rel="noopener"
      target="_blank"
      :title="version"
    ><img
      v-show="buttonState"
      class="p-1"
      style="position:absolute;left: 0"
      :src="iconH"
      :alt="version"
    ></a>
    <div
      class="card-content"
      :style="[ !toElement && !fromElement ? backgroundImage : null ]"
    >
      <div
        class="columns is-mobile is-gapless is-vcentered"
      >
        <div
          ref="fromElement"
          class="column is-half has-text-centered crypto"
        >
          <div
            v-show="!fromElement"
          >
            <h1
              class="title is-3"
              data-id="fromElement"
              @click="formShow"
              title="Click to change"
            >
              <span
                :style="{borderBottomStyle: 'dotted', cursor:'pointer', color}"
              >{{ cryptoName }}</span>
            </h1>
            <input
              ref="editAmount"
              class="input transparent"
              v-show="editAmount"
              v-model="amountInput"
              @input="priceHandler"
              @focus="editAmount = true; buttonState = false"
              @blur="() => { editAmount = false; wsOpen(true); buttonState = true }"
              :style="{color}"
            >
            <label
              v-show="!editAmount"
              :class="['is-medium', (isFetching) ? 'spin' : '']"
              @click="editInput('editAmount')"
              title="Click to change"
            >
              <span
                v-show="!isFetching"
                ref="amount"
              >{{ amountPoint }}</span>
            </label>
          </div>
          <div v-show="fromElement">
            <b-autocomplete
              expanded
              open-on-focus
              check-infinite-scroll
              ref="fromElementAfterClick"
              data-id="fromElement"
              max-height="110px"
              placeholder="Crypto"
              field="symbol"
              class="has-text-left"
              dropdown-position="bottom"
              :data="data"
              :loading="isFetching"
              @select="cryptoAutocompleteHandler"
              @typing="getAsyncData"
              @infinite-scroll="getMoreAsyncData"
            >
              <template #default="props">
                <div>
                  {{ props.option.name }} ({{ props.option.symbol }})
                </div>
              </template>
            </b-autocomplete>
          </div>
        </div>
        <div
          ref="toElement"
          class="column is-half has-text-centered fiat"
        >
          <header>
            <div v-show="!toElement">
              <h1
                class="title is-3"
                data-id="toElement"
                @click="formShow"
                :style="{color}"
                title="Click to change"
              >
                <span :style="{borderBottomStyle: 'dotted', cursor:'pointer', color}">{{ fiatName }}</span>
              </h1>
              <input
                ref="editPrice"
                class="input transparent"
                v-model="priceInput"
                v-show="editPrice"
                @input="amountHandler"
                @focus="editPrice = true; buttonState = false"
                @blur="() => { editPrice = false; wsOpen(true); buttonState = true }"
                :style="{color}"
              >
              <label
                v-show="!editPrice"
                :class="['flash is-medium', `has-text${priceDiffClass}`, (isFetching) ? 'spin' : '']"
                @click="editInput('editPrice')"
                title="Click to change"
              >
                <span
                  ref="price"
                  v-show="!isFetching"
                >{{ pricePoint }}</span>
              </label>
            </div>
          </header>
          <div v-show="toElement">
            <div ref="toElementAfterClick">
              <b-select
                expanded
                v-model="selected"
                ref="selectElement"
                data-id="toElement"
                :loading="fiatLoading"
                @input="selectFiat"
                @blur="() => { toElement = false; wsOpen(true); buttonState = true; }"
              >
                <option
                  v-for="(option, index) in fiatData"
                  :value="index"
                  :key="option.id"
                >
                  {{ option.symbol }} - {{ titleCase(option.id) }}
                </option>
              </b-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer
      v-if="!noFooter"
      :class="['card-footer', darkClass, 'is-block']"
    >
      <div class="columns is-mobile is-gapless has-text-centered is-vcentered">
        <div class="column is-two-fifths">
          Change: <span
            :class="[changeDiffClass, (isFetching) ? 'spin' : '']"
          ><span v-show="!isFetching">{{ change24h }}</span></span>
        </div>
        <div class="column has-text-centered">
          <a
            target="_blank"
            rel="noopener"
            href="https://currencyrate.today/"
          ><span
            :class="['blob', `blob${priceDiffClass}`]"
            :title="currDate"
          >⚡</span></a>
        </div>
        <div
          class="column is-two-fifths"
        >
          Volume: <span :class="[volumeClass, (isFetching) ? 'spin' : '']">
            <span v-show="!isFetching">{{ volume24h }}</span>
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { CountUp } from 'countup.js';
import shortNumber from 'short-number';
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import debounce from 'lodash.debounce';
import startCase from 'lodash.startcase';
import fx from './fx';
import isDarkColor from './is-dark-color';

export default {
  props: {
    crypto: {
      type: String,
      default: 'bitcoin',
    },
    fiat: {
      type: String,
      default: 'united-states-dollar',
    },
    backgroundColor: {
      type: String,
      default: '#ffffff',
    },
    amount: {
      type: Number,
      default: 1,
    },
    decimalPlaces: {
      type: Number,
      default: 2,
      validator(value) {
        return (value >= 0 && value <= 7);
      },
    },
    fontFamily: {
      type: String,
      default: 'sans-serif',
      validator(value) {
        return ['inherit', 'sans-serif', 'serif', 'cursive', 'fantasy', 'monospace'].indexOf(value) !== -1;
      },
    },
    borderRadius: {
      type: String,
      default: '0.25rem',
    },
    live: {
      type: Boolean,
      default: false,
    },
    symbol: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      version: 'Crypto Converter Widget v1.5.1',
      currDate: `${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}`,
      liveState: null,
      buttonState: true,
      isPlayer: false,
      soundEffect: null,
      editPrice: false,
      editAmount: false,
      selected: null,
      cryptoName: 'BTC',
      fiatName: 'USD',
      change: '',
      volume: '',
      offset: 0,
      data: [],
      isFetching: false,
      isFetchingSearch: false,
      fromElement: false,
      toElement: false,
      priceUsd: 0,
      prevPriceUsd: 0,
      countUpPrice: null,
      countUpAmount: null,
      rate: 1,
      options: {
        duration: 0.35,
        prefix: '$',
        decimalPlaces: this.decimalPlaces,
        decimal: '.',
      },
      fiatData: [],
      fiatLoading: false,
      cryptoSearch: '',
      amountInput: 0,
      priceInput: 0,
      pricePoint: 0,
      amountPoint: 0,
      ws_socket: null,
      priceDiff: 0,
      changeDiff: 0,
      cryptoId: '',
      symbolRaw: '$',
    };
  },
  async mounted() {
    this.cryptoId = this.crypto;
    this.amountInput = this.amount;

    this.countUpPrice = new CountUp(this.$refs.price, 0, {
      ...this.options,
      prefix: this.isNullView('$'),
    });
    this.countUpAmount = new CountUp(this.$refs.amount, 0, {
      ...this.options,
      prefix: '',
    });

    this.liveState = this.live || this.isPlayer;
    this.isPlayer = this.liveState;
    await this.request();
    await this.wsOpen(true);

    this.$watch(
      () => this.$refs.fromElementAfterClick.$refs.input.isFocused,
      (val) => {
        if (!val) this.buttonState = true;
        setTimeout(() => {
          this.fromElement = (val) && true;
          if (!val) {
            this.wsOpen(false);
            this.wsOpen(true);
          }
        }, 250, val);
      },
    );
  },
  watch: {
    async crypto(newValue) {
      await this.wsOpen(false);
      this.cryptoId = await newValue;
      this.request();
      await this.wsOpen(true);
    },
    async decimalPlaces(newValue) {
      this.countUpPrice.options.decimalPlaces = await newValue;
      this.countUpAmount.options.decimalPlaces = await newValue;
      this.options.decimalPlaces = await newValue;
      this.countUpPrice.start();
      this.countUpAmount.start();
    },
    fiat(newValue) {
      this.request(newValue);
    },
    amount(newValue) {
      this.amountInput = newValue;
      this.priceHandler();
    },
    priceUsd() {
      this.priceHandler();
    },
    priceDiff() {
      setTimeout(() => {
        this.priceDiff = 0;
      }, 350);
    },
    live(newValue) {
      if (newValue) {
        this.liveState = true;
        this.isPlayer = true;
        this.wsOpen(false);
        this.wsOpen(true);
      } else if (this.ws_socket !== null) this.ws_socket.close(1000);
      this.isPlayer = newValue;
    },
    symbol() {
      this.countUpPrice.options.prefix = this.isNullView(this.symbolRaw);
      this.countUpPrice.start();
    },
    fiatData() {
      this.fiatData.map((obj, index) => {
        if (obj.id === this.fiat) {
          this.selected = index;
        }
        return false;
      });
    },
  },
  computed: {
    colorWG() {
      return isDarkColor(this.backgroundColor) ? 'DarkGray' : 'Gray';
    },
    priceDiffClass() {
      if (this.priceDiff === 1) {
        return isDarkColor(this.backgroundColor) ? '-success' : '-success-dark';
      } if (this.priceDiff === -1) {
        return isDarkColor(this.backgroundColor) ? '-danger' : '-danger-dark';
      }
      return isDarkColor(this.backgroundColor) ? '-light' : '-black-ter';
    },
    changeDiffClass() {
      return {
        [isDarkColor(this.backgroundColor) ? 'has-text-success'
          : 'has-text-success-dark']: this.changeDiff === 1,
        [isDarkColor(this.backgroundColor) ? 'has-text-danger'
          : 'has-text-danger-dark']: this.changeDiff === -1,
        '': this.changeDiff === 0,
      };
    },
    change24h() {
      return `${this.change}%`;
    },
    volume24h() {
      return `$${this.volume}`;
    },
    darkClass() {
      return isDarkColor(this.backgroundColor) ? null : 'card-footer-dark';
    },
    volumeClass() {
      return isDarkColor(this.backgroundColor) ? 'has-text-warning' : 'has-text-warning-dark';
    },
    iconH() {
      return `data:image/svg+xml;utf8,<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path clip-rule="evenodd" d="M5.944.5l-.086.437-.329 1.598a5.52 5.52 0 00-1.434.823L2.487 2.82l-.432-.133-.224.385L.724 4.923.5 5.31l.328.287 1.244 1.058c-.045.277-.103.55-.103.841 0 .291.058.565.103.842L.828 9.395.5 9.682l.224.386 1.107 1.85.224.387.432-.135 1.608-.537c.431.338.908.622 1.434.823l.329 1.598.086.437h3.111l.087-.437.328-1.598a5.524 5.524 0 001.434-.823l1.608.537.432.135.225-.386 1.106-1.851.225-.386-.329-.287-1.244-1.058c.046-.277.103-.55.103-.842 0-.29-.057-.564-.103-.841l1.244-1.058.329-.287-.225-.386-1.106-1.85-.225-.386-.432.134-1.608.537a5.52 5.52 0 00-1.434-.823L9.142.937 9.055.5H5.944z" stroke="${this.colorWG}" stroke-linecap="square" stroke-linejoin="round"></path><path clip-rule="evenodd" d="M9.5 7.495a2 2 0 01-4 0 2 2 0 014 0z" stroke="${this.colorWG}" stroke-linecap="square" stroke-linejoin="round"></path></svg>`;
    },
    iconPlay() {
      return `data:image/svg+xml;utf8,<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 12.5v-10l7 5-7 5z" stroke="${this.colorWG}" stroke-linejoin="round"></path></svg>`;
    },
    iconPause() {
      return `data:image/svg+xml;utf8,<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M5.5 3v9m4-9v9" stroke="${this.colorWG}"></path></svg>`;
    },
    backgroundImage() {
      return {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path fill="${this.colorWG}" d="M3 7H21V9H3zM3 15H21V17H3z"/></svg>')`,
      };
    },
    color() {
      return isDarkColor(this.backgroundColor) ? '#FFFFFF' : '#303742';
    },
  },
  methods: {
    soundToggle() {
      this.isPlayer = !this.isPlayer;
      this.liveState = this.isPlayer;
      if (this.isPlayer) {
        this.wsOpen(true);
      } else {
        this.wsOpen(false);
      }
    },
    isNullView(value) {
      return value !== null && this.symbol === true ? value : '';
    },
    wsOpen(active) {
      if (this.liveState) {
        if (active) {
          const crypto = this.cryptoId;
          this.ws_socket = new WebSocket(`wss://ws.coincap.io/prices?assets=${crypto}`);
          this.ws_socket.onopen = () => {
            console.log(`⚡ ${crypto}`);
            this.prevPriceUsd = 0;
          };
          this.ws_socket.onmessage = (msg) => {
            this.priceUsd = JSON.parse(msg.data)[crypto];
            this.priceDiff = Math.sign(this.priceUsd - this.prevPriceUsd);
            this.prevPriceUsd = this.priceUsd;
          };
        } else if (this.ws_socket) this.ws_socket.close(1000); // CHECK THIS!
      } else if (this.liveState === false && active === false) {
        if (this.ws_socket) this.ws_socket.close(1000);
      }
    },
    async editInput(value) {
      this[value] = !(this[value]);
      this.$nextTick(() => {
        this.$refs[value].focus();
      });
      await this.wsOpen(false);
    },
    titleCase(value) {
      return startCase(value);
    },
    async selectFiat(e) {
      this.fiatName = this.fiatData[e].symbol;
      this.rate = this.fiatData[e].rateUsd;
      this.toElement = false;
      this.priceHandler();
      this.symbolRaw = this.fiatData[e].currencySymbol;
      this.countUpPrice.options.prefix = this.isNullView(this.fiatData[e].currencySymbol);
      this.countUpPrice.start();
      // await this.wsOpen(true);
    },
    priceHandler() {
      this.priceInput = this.roundNumber(fx(this.priceUsd, this.rate) * this.amountInput, this.options.decimalPlaces);
      this.pricePoint = this.priceInput;
      this.amountPoint = this.amountInput;
      this.countUpPrice.update(this.priceInput);
      this.countUpAmount.update(this.amountInput);
    },
    amountHandler() {
      this.amountInput = this.roundNumber(fx(this.rate, this.priceUsd) * this.priceInput, this.options.decimalPlaces);
      this.amountPoint = this.amountInput;
      this.pricePoint = this.priceInput;
      this.countUpPrice.update(this.priceInput);
      this.countUpAmount.update(this.amountInput);
    },
    roundNumber(num, scale) {
      if (!(`${num}`).includes('e')) {
        return +(`${Math.round(`${num}e+${scale}`)}e-${scale}`);
      }
      const arr = (`${num}`).split('e');
      let sig = '';
      if (+arr[1] + scale > 0) {
        sig = '+';
      }
      return +(`${Math.round(`${+arr[0]}e${sig}${+arr[1] + scale}`)}e-${scale}`);
    },
    cryptoAutocompleteHandler(data) {
      if (data !== null) {
        this.priceUsd = data.priceUsd;
        this.prevPriceUsd = data.priceUsd;
        this.cryptoName = data.symbol;
        this.cryptoId = data.id;
        this.volume = shortNumber(parseFloat(data.volumeUsd24Hr)); // volume
        this.change = this.roundNumber(data.changePercent24Hr, 2); // change
        this.changeDiff = Math.sign(this.change); // highlight
        this.priceHandler(); // todo
        // reset with new this.cryptoId
        this.wsOpen(false);
        this.wsOpen(true);
      }
    },
    darkColors(hex) {
      const arr = [
        '#000000',
        '#111111',
        '#222222',
        '#000',
        '#111',
        '#222',
      ];
      return arr.indexOf(hex) !== -1;
    },
    async formShow(el) {
      const id = el.currentTarget.getAttribute('data-id');
      this[id] = (!this[id]) && true;

      if (id === 'toElement') {
        this.buttonState = false;
        this.fromElement = false;
        if (this.fiatData.length === 0) {
          this.fiatLoading = true;
          await axios
            .get('https://api.coincap.io/v2/rates')
            .then(({ data }) => {
              this.fiatData = data.data.sort((a, b) => (a.symbol > b.symbol ? 1 : -1));
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.fiatLoading = false;
            });
        }
        this.$refs.selectElement.focus();
      }
      if (id === 'fromElement' && el.type === 'click') {
        this.buttonState = false;
        this.toElement = false;
        this.getAsyncData(null);
      }

      if (this[id]) {
        this.wsOpen(false);
        this.$nextTick(() => {
          const afterClick = `${id}AfterClick`;
          this.$refs[afterClick].focus();
        });
      } else {
        this.wsOpen(true);
      }
    },
    async request(fiat) {
      this.isFetching = true;
      let money;
      if (fiat === undefined) {
        money = this.fiat;
      } else {
        money = fiat;
      }

      const createUpdateAuthInterceptor = (http) => async (error) => http(error.config);

      axios.interceptors.response.use(
        null,
        createUpdateAuthInterceptor(axios),
      );

      const queue = [];
      // ethereum
      if (this.fiat !== 'united-states-dollar' || fiat !== undefined) {
        const url1 = `https://api.coincap.io/v2/rates/${money}`;
        const req1 = await axios.get(url1);
        queue.push(req1);
      }
      const url2 = `https://api.coincap.io/v2/assets/${this.cryptoId}`;
      const req2 = await axios.get(url2);
      queue.push(req2);

      await axios
        .all(queue)
        .then(
          axios.spread((...responses) => {
            responses.forEach((response, index) => {
              if (response !== undefined) {
                if (response.data.data.currencySymbol !== undefined) {
                  this.symbolRaw = response.data.data.currencySymbol;
                  this.countUpPrice = new CountUp(this.$refs.price, 0, {
                    ...this.options,
                    prefix: this.isNullView(response.data.data.currencySymbol),
                  });
                }
                if (queue.length > 1 && index === 0) {
                  this.rate = response.data.data.rateUsd;
                  this.symbolRaw = response.data.data.currencySymbol;
                  this.options.prefix = this.isNullView(response.data.data.currencySymbol);
                  this.fiatName = response.data.data.symbol;
                }
                this.cryptoName = response.data.data.symbol;
                this.priceUsd = response.data.data.priceUsd;
                this.prevPriceUsd = response.data.data.priceUsd;
                this.volume = shortNumber(parseFloat(response.data.data.volumeUsd24Hr)); // volume
                this.change = this.roundNumber(response.data.data.changePercent24Hr, 2); // change
                this.changeDiff = Math.sign(this.change); // highlight
              }
            });
          }),
        )
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    getAsyncData: debounce(async function find(search) {
      this.isFetchingSearch = true;
      let url;
      const limit = 100;

      if (typeof search === 'string') {
        if (this.cryptoSearch !== search) {
          this.cryptoSearch = search;
          this.offset = 0;
          this.data = [];
        }
        url = `https://api.coincap.io/v2/assets?search=${search}&limit=${limit}&offset=${this.offset}`;
      } else {
        url = `https://api.coincap.io/v2/assets?limit=${limit}&offset=${this.offset}`;
      }
      await axios
        .get(url)
        .then(({ data }) => {
          this.offset += limit;
          data.data.forEach((item) => this.data.push(item));
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isFetchingSearch = false;
        });
    }, 250),
    getMoreAsyncData: debounce(function find() {
      this.getAsyncData(this.cryptoSearch);
    }, 250),
  },
};
</script>
